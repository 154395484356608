<template>
  <div>
    <div class="uk-text-large">
      {{ displayQuery }}
    </div>

    <div uk-grid class="flex flex-col md:flex-row">
      <div class="m-auto w-full mb-6 md:w-1/4 md:mr-6 md:mt-4">
        <div>
          <div
            class="uk-card uk-padding-small uk-card-default uk-border-rounded"
          >
            <div class="hidden">
              <div class="uk-text-bold">Color</div>
            <select class="uk-select uk-border-rounded" v-model="color">
              <option :value="null">Todos</option>
              <option
                :value="color"
                v-for="(color, index) in colorsColors"
                :key="index"
              >
                {{ color }}
              </option>
            </select>
            </div>

            <div class="uk-margin-small-top uk-text-bold flex items-center mb-4 ">
              <div>
                  <img :src="pointsIcon" :alt="pointsName" class="w-12">
              </div>
              <div>
                {{ pointsName }}:
              </div>
            </div>
            <div class="flex items-center justify-center">
              <div class="w-16 bg-gray-700 rounded-full text-sm text-white px-2 py-1 text-center">
                {{price_filter_selected}}
              </div>
            </div>
            <div class="flex items-center justify-center"> 
                <div class="mr-4">
                  {{ price_range.min }}
                </div>
                <div>
                  <input id="default-range" type="range" v-model="price_filter_selected" min="0" max="2500" class="w-full h-2 uk-background-primary rounded-lg appearance-none cursor-pointer dark:bg-gray-700">
                </div>
                <div class="ml-4">
                  {{ price_range.max }}
                </div>
            </div>
            <hr>
            <div class="flex justify-center mt-2">
              <button class="flex items-center justify-center uk-background-primary rounded-full text-sm text-white px-4 py-2 text-center">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                  </svg>
                </div>
                <div class="ml-2 font-bold">
                  Filtrar
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="m-auto w-full md:w-3/4">
        <div v-if="tag" class="uk-margin-bottom">
          <img width="100%" :src="tag.image" alt="" />
        </div>
        <pagination-buttons
          :prev-page-url="products.prev_page_url"
          :is-fetching="isLoading"
          :current-page="products.current_page"
          :last-page="products.last_page"
          :next-page-url="products.next_page_url"
        ></pagination-buttons>
        <div v-if="!isLoading">
          <div
            class="
              uk-margin-small-top
              uk-child-width-1-2@s
              uk-child-width-1-3@m
              uk-child-width-1-4@l
              uk-grid-match
            "
            uk-grid
            v-if="displayProducts.length !== 0"
          >
            <div v-for="product in displayProducts" :key="product.id">
              <ProductCard
                :product="product"
                :setActive="setActive"
                :showQuickview="false"
              />
            </div>
          </div>
          <div v-else class="uk-margin-top">
            No se han encontrado productos con los filtros seleccionados.
          </div>
        </div>
        <div v-else class="uk-text-center uk-margin-top">
          <div uk-spinner></div>
        </div>
        <pagination-buttons
          class="uk-margin-top uk-margin-bottom"
          :prev-page-url="products.prev_page_url"
          :is-fetching="isLoading"
          :current-page="products.current_page"
          :last-page="products.last_page"
          :next-page-url="products.next_page_url"
        ></pagination-buttons>
      </div>
    </div>

    <ProductQuickview :product="activeProduct" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProductCard from "../Utils/ProductCard";
import ProductQuickview from "../Utils/ProductQuickview";

export default {
  name: "StoreSearch",

  components: {
    ProductCard,
    ProductQuickview,
  },

  data() {
    return {
      price_range: {
        min: 0,
        max: 2500,
      },

      price_filter_selected: 0,

      displayQuery: "",
      products: {
        data: [],
      },
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      itemsPerPage: this.$route.query.items
        ? parseInt(this.$route.query.items)
        : 20,
      originalSearch: this.$route.query.query ?? null,
      category: this.$route.query.category ?? null,
      color: this.$route.query.color ?? null,
      tags: this.$route.query.tags ?? null,
      filteredColors: [],
      tag: null,
      price: null,
      isLoading: true,
      searchTimer: null,
      activeProduct: null,
    };
  },

  mounted() {
    this.colorsFetchColors();
    this.initializeSearch();
  },

  computed: {
    ...mapGetters([
      "colorsColors",
      "colorsIsLoadingColors",
      "storefrontStorefront",
      "pointsIcon",
      "pointsName"
    ]),
    campaign() {
      return this.$route.query.campaign ?? null;
    },
    urlCategory() {
      return this.$route.query.category;
    },
    search() {
      return this.$route.query.query;
    },
    queryParams() {
      const params = {};
      if (this.page) {
        params["page"] = this.page;
      }
      if (this.search) {
        params["query"] = this.search;
      }
      if (this.itemsPerPage) {
        params["items"] = this.itemsPerPage;
      }
      if (this.category) {
        params["category"] = this.category;
      }
      if (this.tags) {
        params["tags"] = this.tags;
      }
      if (this.campaign) {
        params["campaign"] = this.campaign;
      }
      if (this.color) {
        params["color"] = this.color;
      }

      return params;
    },
    displayProducts() {
      return this.products.data.map((product) => {
        const startingAtQuantity = product.prices
          ? Math.min(...Object.keys(product.prices))
          : null;
        const startingAtPrice = product.prices
          ? product.prices[startingAtQuantity]
          : null;
        return {
          ...product,
          // name: product.filled_fields.filter(
          //     (filledField) => filledField.field.slug === "name"
          // )[0].value,
          main_image: this.extractImage(product, this.filteredColors),
          startingAtPrice,
          startingAtQuantity,
        };
      });
    },
  },

  methods: {
    ...mapActions(["colorsFetchColors"]),
    extractImage(product) {
      if (product.variations && product.variations.length !== 0) {
        const variationImage = product.variations
          .map((variation) => this.extractImage(variation))
          .filter((image) => image);
        if (variationImage.length !== 0) {
          return variationImage[0];
        }
      }
      if (product.children && product.children.length !== 0) {
        const childrenImage = product.children
          .map((children) => this.extractImage(children))
          .filter((image) => image);
        if (childrenImage.length !== 0) {
          return childrenImage[0];
        }
        return null;
      }
      if (
        this.storefrontStorefront.type === "b2b" &&
        product.media.length !== 0
      ) {
        return product.media[0];
      }
      if (
        this.storefrontStorefront.type === "redeem" &&
        product.storefront_redeemables.length !== 0 &&
        product.storefront_redeemables[0].media.length !== 0
      ) {
        return product.storefront_redeemables
          .map((storefrontRedeemable) => storefrontRedeemable.media)
          .flat()[0];
      }
      return null;
    },
    setActive(product) {
      this.activeProduct = product;
    },
    initializeSearch() {
      this.isLoading = true;
      this.axios
        .get("/store/search", {
          params: this.queryParams,
        })
        .then(
          ({
            data: { displayQuery, products, filteredColors, tag },
            request: { responseURL },
          }) => {
            const urlParams = new URLSearchParams(responseURL.split("?")[1]);
            if (
              !Object.keys(this.queryParams).every(
                (queryKey) =>
                  urlParams.has(queryKey) &&
                  this.queryParams[queryKey] === urlParams.get(queryKey)
              )
            ) {
              const replaceParams = {};
              urlParams.forEach((value, key) => {
                replaceParams[key] = value;
              });

              this.$router.push({
                query: replaceParams,
              });
            }
            this.displayQuery = displayQuery;
            this.products = products;
            this.itemsPerPage = products.per_page;
            this.filteredColors = filteredColors;
            this.tag = tag;
          }
        )
        .catch((error) => {
          if (
            error.response.status === 404 &&
            error.response.data.message == "unknown_campaign"
          ) {
            this.$router.push("/store");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchPage(event, url) {
      this.isLoading = true;
      if (url) {
        const urlParams = new URLSearchParams(url.split("?")[1]);
        this.page = urlParams.get("page");
      }

      this.$router.replace({
        query: this.queryParams,
      });
      this.axios
        .get(url ? url : "/store/search", {
          params: this.queryParams,
        })
        .then(({ data: { products, filteredColors, displayQuery, tag } }) => {
          this.products = products;
          this.filteredColors = filteredColors;
          this.displayQuery = displayQuery;
          this.tag = tag;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  watch: {
    itemsPerPage() {
      this.products.current_page = 1;
      this.page = 1;
      this.fetchPage();
    },
    urlCategory(value) {
      this.category = value;
      this.products.current_page = 1;
      this.page = 1;
      this.fetchPage();
    },
    color() {
      this.products.current_page = 1;
      this.page = 1;
      this.fetchPage();
    },
    price() {
      this.products.current_page = 1;
      this.page = 1;
      this.fetchPage();
    },
    search() {
      this.products.current_page = 1;
      this.page = 1;
      this.fetchPage();
    },
  },
};
</script>